import { navigate } from "gatsby";

const RedirectComponent = (item) => {
  if (typeof window !== "undefined") {
    navigate(item.pageContext.to);
  }
  return null;
};

export default RedirectComponent;
